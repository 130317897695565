import { Injectable } from '@angular/core';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import { id } from '@cca-infra/common';
import { UserSequenceViewModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class SequenceDevService extends BaseApiService {
  constructor() {
    super(`sequence.v1.dev`);
  }

  getAllStepNames(sequenceName: string) {
    return this.http.get<string[]>(
      `${this.url}get-step-names/${sequenceName}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  navigate<T extends UserSequenceViewModel>(
    sequenceName: string,
    stepName: string,
    entityId: id | undefined,
  ) {
    if (entityId) {
      return this.http.get<T>(
        `${this.url}navigate/${sequenceName}/${stepName}/${entityId}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    } else {
      return this.http.get<T>(
        `${this.url}navigate/${sequenceName}/${stepName}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    }
  }

  getExpectedStepValueKeys(stepId: id) {
    return this.http.post(`${this.url}get-expected-step-value-keys/${stepId}`, {
      context: HttpContextBuilder({
        sequence: true,
      }),
    });
  }
}
