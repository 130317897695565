import { Injectable } from '@angular/core';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import { id } from '@cca-infra/common';
import {
  CompleteAndRepeatStepRequest,
  CompleteStepRequest,
  CopyUserStateRequest,
  NavigateUserStateRequest,
  UserSequenceViewModel,
} from '../model';

export type SequenceUser = {
  userId: id;
  userName: string;
};

@Injectable({
  providedIn: 'root',
})
export class SequenceUserStateService extends BaseApiService {
  constructor() {
    super(`sequence.v1.userState`);
  }

  getState<T extends UserSequenceViewModel>(
    sequenceName: string,
    entityId?: id,
  ) {
    if (entityId) {
      return this.http.get<T>(
        `${this.url}get-state/${sequenceName}/${entityId}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    } else {
      return this.http.get<T>(`${this.url}get-state/${sequenceName}`, {
        context: HttpContextBuilder({
          sequence: true,
        }),
      });
    }
  }

  reset<T extends UserSequenceViewModel>(sequenceName: string, entityId?: id) {
    if (entityId) {
      return this.http.delete<T>(
        `${this.url}reset/${sequenceName}/${entityId}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    } else {
      return this.http.delete<T>(`${this.url}reset/${sequenceName}`, {
        context: HttpContextBuilder({
          sequence: true,
        }),
      });
    }
  }

  completeStep<T extends UserSequenceViewModel>(
    completeStepRequest: CompleteStepRequest,
  ) {
    return this.http.post<T>(`${this.url}complete-step`, completeStepRequest, {
      context: HttpContextBuilder({
        sequence: true,
      }),
    });
  }

  completeAndRepeatStep<T extends UserSequenceViewModel>(
    completeStepRequest: CompleteAndRepeatStepRequest,
  ) {
    return this.http.post<T>(
      `${this.url}complete-and-repeat-step`,
      completeStepRequest,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  navigate<T extends UserSequenceViewModel>(
    navigateStepRequest: NavigateUserStateRequest,
  ) {
    return this.http.post<T>(`${this.url}navigate`, navigateStepRequest, {
      context: HttpContextBuilder({
        sequence: true,
      }),
    });
  }

  getUsers(sequenceName: string, entityId?: id) {
    if (entityId) {
      return this.http.get<SequenceUser[]>(
        `${this.url}get-users/${sequenceName}/${entityId}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    } else {
      return this.http.get<SequenceUser[]>(
        `${this.url}get-users/${sequenceName}`,
        {
          context: HttpContextBuilder({
            sequence: true,
          }),
        },
      );
    }
  }

  copyState<T extends UserSequenceViewModel>(request: CopyUserStateRequest) {
    return this.http.post<T>(`${this.url}copy`, request, {
      context: HttpContextBuilder({
        sequence: true,
      }),
    });
  }
}
