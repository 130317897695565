/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NoRepeat  1 = Every1Week  2 = Every2Weeks  3 = Every3Weeks  4 = Every4Weeks
 */
export enum ShipmentFrequency {
  NoRepeat = 0,
  Every1Week = 1,
  Every2Weeks = 2,
  Every3Weeks = 3,
  Every4Weeks = 4,
}
