import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BaseApiService, HttpContextBuilder } from '@cca-infra/core';
import { id } from '@cca-infra/common';
import {
  BookingPriceViewModel,
  CO2DataViewModel,
  CalculateCO2DataRequest,
  GenerateRecurringShipmentsRequest,
  RecurringShipmentViewModel,
  ShipmentDTO,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class SequenceUtilityService extends BaseApiService {
  constructor() {
    super(`sequence.v1.utility`);
  }

  calculateCO2Data(requestData: CalculateCO2DataRequest) {
    return this.http.post<CO2DataViewModel>(
      `${this.url}calculate-co2-data`,
      {
        ...requestData,
      },
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  generateRecurringShipments(requestData: GenerateRecurringShipmentsRequest) {
    return this.http.post<RecurringShipmentViewModel[]>(
      `${this.url}generate-recurring-multistop-shipments`,
      {
        ...requestData,
      },
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }

  downloadReferencesTemplate(sequenceName: string) {
    return this.http.post<ArrayBuffer>(
      `${this.url}references/download/${sequenceName}`,
      null,
      {
        responseType: 'arraybuffer' as 'json',
        context: HttpContextBuilder({ sequence: true }),
      },
    );
  }

  uploadReferences(sequenceName: string, file: File) {
    const formData = new FormData();
    formData.set('file', file, file.name);

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');

    return this.http.post<ShipmentDTO[]>(
      `${this.url}references/upload/${sequenceName}`,
      formData,
      { headers: headers, context: HttpContextBuilder({ sequence: true }) },
    );
  }

  getBookingPrices(userStateId: id) {
    return this.http.get<BookingPriceViewModel>(
      `${this.url}get-booking-prices/${userStateId}`,
      {
        context: HttpContextBuilder({
          sequence: true,
        }),
      },
    );
  }
}
