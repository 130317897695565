/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = StandTrailer  2 = SwapTrailer  3 = SelfLoading
 */
export enum TrailerOperationType {
  Undefined = 0,
  StandTrailer = 1,
  SwapTrailer = 2,
  SelfLoading = 3,
}
