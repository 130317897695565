/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = OnTheFirstSameDayNumber  2 = OnTheFirstSameDayOfWeek  3 = OnTheSecondSameDayOfWeek  4 = OnTheThirdSameDayOfWeek  5 = OnTheFourthSameDayOfWeek  6 = OnTheLastSameDayOfWeek
 */
export enum MonthlyRepeatOptionType {
  Undefined = 0,
  OnTheFirstSameDayNumber = 1,
  OnTheFirstSameDayOfWeek = 2,
  OnTheSecondSameDayOfWeek = 3,
  OnTheThirdSameDayOfWeek = 4,
  OnTheFourthSameDayOfWeek = 5,
  OnTheLastSameDayOfWeek = 6,
}
